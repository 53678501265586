import React, { Component, Fragment, version } from "react";
import CampaignHolder from "./Styles/campaignComponentStyle";
import CampaignTopbar from "./CampaignTopbar";
import AdScreenCardContainer from "../containers/AdScreenCardContainer";
import { Row, Col, Table, Button, Card, notification, Modal, Skeleton, Typography, Collapse, Badge, Icon, Tooltip, Alert} from "antd";
import { withRouter } from "react-router";
import moment from "moment";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { compose } from "recompose";
import marker from "../images/marker-latinad.png";
import markerBus from "../images/marker-bus.png";
import markerIndoor from "../images/marker-indoor.png";
import markerOutdoor from "../images/marker-outdoor.png";
import markerPos from "../images/marker-pos.png";
import placeholder from "../images/bg.jpg";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import { Link } from "react-router-dom";
import MapHolder from "./Styles/mapStyleCampaigns";
import { withTranslation } from 'react-i18next';
import Slider from "react-slick";
import { difference } from "lodash";
import NumberFormat from "./NumberFormat";
import { getUrl, SeparateNumberFormat, getRelationId, hasAccessRole} from "../services/utils";
import UserRoleEnum from "../constants/UserRoleEnum";
import AudienceSummaryComponent from "./AudienceSummaryComponent";
import Colors from "../themes/Colors";
import CardOdcModal from "./CardOdcModal";
import ReplicateCampaignContainer from "../containers/ReplicateCampaignContainer";
import * as markersLatinad from '../themes/makersLatinad';
import * as markersWhiteLabel from '../themes/makersWhiteLabel';
import { getDomain } from "../services/utils";
import { getCMSLink } from "../services/cmsLogin";

const { Paragraph } = Typography;
const { Panel } = Collapse;

function SliderNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "6px", top: "17px"}}
      onClick={onClick}
    />
  );
}

function SliderPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "6px", top: "17px", zIndex: "1"}}
      onClick={onClick}
    />
  );
}

const domain = getDomain(window.location.hostname);
const markers = domain === "latinad" ? markersLatinad : markersWhiteLabel;

const DisplayMap = withTranslation()(compose(withGoogleMap)(
  class DisplayMapComponent extends Component {

    constructor() {
      super();
      this.state = {
        visibleWarningNoAutomateView: false
      };
    }

    showWarningNoAutomateModal = () => {
      this.setState({visibleWarningNoAutomateView: true});
    }

    okWarningNoAutomateModal = e => {
      this.setState({visibleWarningNoAutomateView: false});
    };

    cancelWarningNoAutomateModal = e => {
      this.setState({visibleWarningNoAutomateView: false});
    };

    render() {
      const { t } = this.props;
      const { displays = [] } = this.props;
      let defaultCenter = { lat: -34.397, lng: 150.644 };

      if (displays.length > 0) {
        defaultCenter = {
          lat: parseFloat(displays[0].display_latitude, 10),
          lng: parseFloat(displays[0].display_longitude, 10)
        };
      }

      return (
        <GoogleMap defaultZoom={10} center={defaultCenter}>
          {displays.map(display => {
            const position = {
              lat: parseFloat(display.display_latitude, 10),
              lng: parseFloat(display.display_longitude, 10)
            };


            let markerDisplay = domain === "latinad" ? marker: markers.marker;
            if (display.display_location_type === 'buses') {
              markerDisplay = domain === "latinad" ? markerBus: markers.markerBus();
            } else if (display.display_location_type === 'indoor') {
              markerDisplay = domain === "latinad" ? markerIndoor: markers.markerIndoor();
            } else if (display.display_location_type === 'outdoor') {
              markerDisplay = domain === "latinad" ? markerOutdoor: markers.markerOutdoor();
            } else if (display.display_location_type === 'pos') {
              markerDisplay = domain === "latinad" ? markerPos: markers.markerPos();
            } else {
              markerDisplay = domain === "latinad" ? marker: markers.marker();
            }

            return (
              <Marker
                key={display.id}
                icon={{
                  url: markerDisplay,
                  size: { width: 30, height: 37 },
                  scaledSize: { width: 30, height: 37 },
                }}
                onClick={() => this.props.openDisplay(display)}
                position={{
                  lat: parseFloat(display.display_latitude),
                  lng: parseFloat(display.display_longitude),
                }}
              >
                {this.props.currentDisplay === display && (
                  <InfoBox
                    onCloseClick={() => this.props.closeDisplay()}
                    options={{
                      closeBoxURL: ``,
                      enableEventPropagation: true,
                    }}
                  >
                    <>
                      <Button
                        className="screenCardMapCloseButton"
                        onClick={() => this.props.closeDisplay()}
                        shape="circle"
                        icon="close"
                      />
                      <div className="screenCardMap">
                        <div
                          className="screenCardMapContent"
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={
                              display.display_picture
                                ? display.display_picture
                                : placeholder
                            }
                            className="screenCardMapImage"
                            alt=""
                          />
                          <div className="screenCardContentInImage">
                            <div>
                              <Paragraph
                                ellipsis
                                className="screenCardMapTitle"
                              >
                                {display.display_name}
                              </Paragraph>
                              {/* {display.display_use_cms === 1 ? (
                                <Tooltip
                                  placement="bottomLeft"
                                  title={t(
                                    "Non-automated screen. Click to see detail"
                                  )}
                                >
                                  <Button
                                    className="screenWarningNoAutomateView"
                                    shape="circle"
                                    size="small"
                                    onClick={() =>
                                      this.showWarningNoAutomateModal()
                                    }
                                  >
                                    <Icon
                                      type="warning"
                                      theme="outlined"
                                      style={{ color: "#ffc107" }}
                                    />
                                  </Button>
                                </Tooltip>
                              ) : null}
                              <Modal
                                title={t("Non-automated screen")}
                                visible={
                                  this.state.visibleWarningNoAutomateView
                                }
                                onOk={this.okWarningNoAutomateModal}
                                onCancel={this.cancelWarningNoAutomateModal}
                              >
                                <p style={{ margin: "25px" }}>
                                  {t(
                                    "This screen does not have the real-time print notice. Therefore, print reports may take up to 48 hours to be reflected."
                                  )}
                                </p>
                              </Modal> */}
                            </div>
                          </div>
                          <div className="screenCardMapDownInfo">
                            <div className="screenCardMapInfo">
                              {t("Screen resolution")}:{" "}
                              <b>
                                {display.display_resolution_width +
                                  "x" +
                                  display.display_resolution_height}
                              </b>
                            </div>
                            <div className="screenCardMapInfo">
                              {t("Announcement resolution")}:{" "}
                              <b>
                                {display.content_width +
                                  "x" +
                                  display.content_height}
                              </b>
                            </div>
                            <div className="screenCardMapInfo">
                              {display.belong_to_smart_campaign
                                ? t("Purchased impressions")
                                : t("Promised impressions")}
                              <b>
                                {" "}
                                {SeparateNumberFormat(display.promised_shows)}
                              </b>
                            </div>
                            {/* <div className="screenCardMapDisplayDetailButton">
                              <Link to={"/screen/" + display.display_id}>
                                <Button
                                  className="screenCardMapDisplayDetail"
                                  style={{ width: "100%" }}
                                >
                                  {t("Screen details")}
                                </Button>
                              </Link>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </>
                  </InfoBox>
                )}
              </Marker>
            );
          })}
        </GoogleMap>
      );
    }
  }
)); // Refactor this DisplayMap

const statePaymentCampaign = (payment_status, t, isAgency) => {
  let status = '';
  let color = '';
  if (payment_status === 'pending' || payment_status === 'pre_approved'){
    status = t('Pending');
    color = '#fa8c16';
  }

  if (payment_status === 'approved') {
    status = t('Approved');
    color = '#52c41a';
  }

  if (payment_status === 'rejected') {
    status = t('Rejected');
    color = '#f96868';
  }

  if (payment_status === 'cancelled') {
    status = t('Cancelled');
    color = '#f96868';
  }

  return (
    <Tooltip
      placement="top"
      title={
        payment_status === 'pending' && (
        isAgency
          ? t('Campaign pending approval status, check with your manager.')
          : t('Campaign in pending payment status.')
        )
      }
    >
      <Badge
        count={status}
        style={{ backgroundColor: color }}
      />
    </Tooltip>
  );
};

class CampaignComponent extends Component {
  state = {
    displayMapModal: false,
    editCampaignModal: false,
    pausedCampaignModal: false,
    audienceModaVisible: false,
    loadingEditCampaign: false,
    displays: [],
    currentDisplay: null,
    reportLoading: false,
    totalShows: 0,
    loadInitialReport: false,
    isAgencyOwner: false,
  };

  componentDidMount() {
    if (this.props.user) {
      const campaignId = this.props.match.params.id;
      this.props.getCampaign(campaignId);
      this.props.getCampaignPayments(campaignId);
      this.setState({ loadInitialReport: true });
    } else {
      this.props.setShouldPromptLogin(true, window.location.pathname);
      this.props.history.push("/");
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { fetchingCampaign: wasFetchingCampaign, user, currentCampaign, isSuperUser, setNewVersionCampaign } = this.props;
    const { fetchingCampaign, errors } = nextProps;
    // check if all displays are loade
    if (!nextProps.fetchingDisplaysInfo && (this.props.fetchingDisplaysInfo && !nextProps.fetchingDisplaysInfo)) {
      this.setState({ loadingEditCampaign: false });

      if( nextProps.currentCampaign.new_ssp ) {
        this.props.history.push("/campaignManager");
        setNewVersionCampaign(true);
      } else {
        this.props.history.push("/videoUpload");
        setNewVersionCampaign(false);
      }
    }
    // Check if the user is the owner of the campaign and is agency
    if( currentCampaign && ((user && user.company && !this.state.isAgencyOwner ) || isSuperUser)) {
      if (currentCampaign.company && ((currentCampaign.company.id === user.company.id))) {
        if (user.company.type === 'agency') {
          this.setState({ isAgencyOwner: true });
        }
      }
    }

    if (wasFetchingCampaign && !fetchingCampaign) {
      if (errors && errors.length > 0) {
        if (errors[0].indexOf("403") >= 0) {
          notification.error({
            duration: 5,
            placement: "bottomRight",
            message: this.props.t('There was an error'),
            description: this.props.t('You do not have permissions to access this campaign')
          });
        }

        if (errors[0].indexOf("No results") >= 0) {
          notification.error({
            duration: 2,
            placement: "bottomRight",
            message: this.props.t('There was an error'),
            description: this.props.t('The requested campaign does not exist')
          });
        }

        this.props.history.push("/campaigns");
      } else {
        var arrayDisplays = [];
        var arrayContents = [];
        nextProps.currentCampaign.contents_displays.map((display, index) => {
          arrayDisplays.push(display.display_id)
          arrayContents.push(display.content_id)
        });
        this.props.getSingleReportRequest({
          campaign: nextProps.currentCampaign.id,
          displays: arrayDisplays.toString(),
          contents: arrayContents.toString(),
          from_date: moment(nextProps.currentCampaign.start_date).format('YYYY-MM-DD'),
          to_date: moment(nextProps.currentCampaign.end_date).format('YYYY-MM-DD'),
          is_campaign: 1
        })
      }

    }
    if (this.props.loadingSingleReport && !nextProps.loadingSingleReport && this.state.loadInitialReport) {
      var totalShows = 0;
      if (nextProps.reportSingleData && nextProps.reportSingleData.report && nextProps.reportSingleData.report.length > 0) {
        totalShows = nextProps.reportSingleData.report.reduce(
          (acc, display) => {
            return acc + display.shows;
          },
          0
        );
      }
      this.setState({ totalShows: totalShows });
      this.setState({ loadInitialReport: false });
    }

    let lenguageParse
    switch (nextProps.i18n.language) {
      case "es":
        lenguageParse = "esLA";
        break;
      case "es_ar":
        lenguageParse = "es";
        break;
      default:
        break;
    }
    if (this.props.loadingReport && !nextProps.loadingReport && this.state.reportLoading) {
      this.setState({ reportLoading: false });
      const parameter = `lenguage=${lenguageParse || nextProps.i18n.language}&token=${nextProps.reportToken.token}`;
      window.open(getUrl(window.location, "report.html", parameter));
    }
    if (this.props.loadingProposal && !nextProps.loadingProposal && this.state.proposalLoading) {
      const parameter = `lenguage=${lenguageParse || nextProps.i18n.language}&token=${nextProps.proposalToken.token}`
      window.open(getUrl(window.location, "proposal.html", parameter));
      this.setState({ proposalLoading: false });
    }
  }

  handleAudienceModal = () => {
    this.setState(prevState => ({
      audienceModaVisible: !prevState.audienceModaVisible,
    }));
  }


  getProposal = (campaign_id) => {
    this.setState({ proposalLoading: true });
    this.props.getProposalRequest({
        campaign_id: campaign_id
    })
  }
  getReport = (campaign) => {
    const { user } = this.props;
    var arrayDisplays = [];
    var arrayContents = [];
    campaign.contents_displays.map((display, index) => {
      arrayDisplays.push(display.display_id)
      arrayContents.push(display.content_id)
    });
    if ( user && hasAccessRole(user.roles, [UserRoleEnum.agencyMember, UserRoleEnum.companyMember])) {
      this.props.getReportRequest({
        properties: {
          displays: arrayDisplays.toString(),
          contents: arrayContents.toString(),
          from_date: moment(campaign.start_date).format('YYYY-MM-DD'),
          to_date: moment(campaign.end_date).format('YYYY-MM-DD'),
          display_data: 1,
          company: campaign.company_id
        }
      })
    } else {
      this.props.getReportRequest({
        properties: {
          displays: arrayDisplays.toString(),
          contents: arrayContents.toString(),
          from_date: moment(campaign.start_date).format('YYYY-MM-DD'),
          to_date: moment(campaign.end_date).format('YYYY-MM-DD'),
          display_data: 1
        }
      })
    }
    this.setState({ reportLoading: true });
  }

  editCampaignModal = () => {
    this.setState({ editCampaignModal: true });
  }

  pausedCampaignModal = () => {
    this.setState({ pausedCampaignModal: true });
  }

  replicateCampaignModal = () => {
    this.setState({ replicateCampaignModal: true });
  }

  // Get info of displays of campaign and loader cart && currentDisplay && currentCampaign
  getDisplaysOfCampaign = (start_date, end_date ) => {
    const { currentCampaign } = this.props;

    let listDisplaysNotOnCart = [...new Set(
        currentCampaign.contents_displays.map(d => d.display_id)
    )];

    if( listDisplaysNotOnCart.length === 0 ) {
      notification.error({
        message: "error al cargar pantallas",
        description: "error al cargar pantallas",
        duration: 10
      });
      this.setState({ editCampaignModal: false })
      this.setState({ loadingEditCampaign: false });
      return
    }

    let params = {
      date_from: start_date,
      date_to: end_date,
      currency: currentCampaign.currency,
      invoice_issuing_country: currentCampaign.invoice_issuing.alpha_2_code,
      client_id: currentCampaign.client ? currentCampaign.client.id : null,
      list_id: listDisplaysNotOnCart,
      external_dsp_id: currentCampaign.external_dsp_id,
      smart_campaign_enabled: currentCampaign.programmatic_provider_id ? 1 : null,
      is_campaign: currentCampaign ? 1 : 0,
     }

    this.props.getDisplaysInfoRequest(params);
  }

  pauseCampaign = (campaign, notPaused) => {
    const pausedCampaign = {
        contents_displays: campaign.contents_displays.map(content => ({
          ...content,
          rules: content.rules && content.rules.length > 0 ? JSON.parse(content.rules) : null,
          display_id: content.display_id,
          active: notPaused ? 0 : 1,
          relation_id: content.id,
      })),
    };
    this.props.editCampaign( campaign.id, pausedCampaign)
    this.setState({ pausedCampaignModal: false });
  }

  editCampaign = async (campaign) => {
    this.setState({ loadingEditCampaign: true });
    if(!this.props.categories || this.props.categories.length === 0){
      this.props.getCategories();
    }

    this.props.clearCampaignData();
    let content = [campaign.contents_displays[0]]
    // Is smart list
    if(campaign.contents_displays[0].content_type == "smartv2" &&
       campaign.contents_displays[0].content_child_contents &&
       campaign.contents_displays[0].content_child_contents.length > 0
      ){
      content = campaign.contents_displays[0].content_child_contents
    }
    if(!campaign.new_ssp) {
      const defaultContent = {
        file: content[0].content_file ? content[0].content_file: content[0].file,
        file_thumb: content[0].content_file ? content[0].content_file: content[0].file,
        width: content[0].content_width ? content[0].content_width: content[0].width,
        height: content[0].content_height ? content[0].content_height: content[0].height,
        id: content[0].content_id ? content[0].content_id: content[0].id,
        name: content[0].content_name ? content[0].content_name: content[0].name,
        type: content[0].content_type ? content[0].content_type: content[0].type,
        length: content[0].content_length ? content[0].content_length: content[0].length,
        content_version_name: content[0].content_version_name,
        ready: true,
      }
      this.props.setDefaultContent(defaultContent);
    } else {
        this.props.setToleranceResolutionInit(campaign.tolerance_resolution || 0);
        this.props.setToleranceResolution(campaign.tolerance_resolution );
    }
   // this.props.removeDisplaysCart();

    var arrayDisplays = [];
    campaign.contents_displays.map((display, index) => {
      if (!arrayDisplays.find(d => d === display.display_id)) {
        arrayDisplays.push(display.display_id)
      }
    });

    var arrayDisplaysCart = [];
    for (var i = 0; i < this.props.cart.length; i++) {
      arrayDisplaysCart.push(this.props.cart[i].id)
    }

    var displaysRemoveCart = difference(arrayDisplaysCart, arrayDisplays);
    for (var i = 0; i < displaysRemoveCart.length; i++) {
      this.props.removeDisplayFromCart(displaysRemoveCart[i])
    }

    let start_date = campaign.start_date && moment(campaign.start_date).format("YYYY-MM-DD");
    let end_date = campaign.end_date && moment(campaign.end_date).format("YYYY-MM-DD");

    this.getDisplaysOfCampaign(start_date, end_date);

    this.props.setDates(start_date, end_date);
    this.props.setCampaignName(campaign.name);
    this.props.setProgrammatic(campaign.smart_campaign === 1 ? 'programmatic' : 'traditional')
    this.props.setBudget(parseFloat(campaign.total_price_with_profit));

    for (var i = 0; i < campaign.contents_displays.length; i++) {
      let contents = [campaign.contents_displays[i]]
      // Is smart list
      if(campaign.contents_displays[i].content_child_contents &&
         campaign.contents_displays[i].content_type == "smartv2"
        ){
        contents = campaign.contents_displays[i].content_child_contents
      }

      contents.forEach((content, indice) => {
        var data = {
          relationId: content.campaign_id ? campaign.contents_displays[i].id : getRelationId( campaign.contents_displays[i].id, indice),
          indice_relation: !content.campaign_id ? indice: null,
          active: campaign.contents_displays[i].active,
          displayId: campaign.contents_displays[i].display_id,
          fill_screen: campaign.contents_displays[i].fill_screen,
          file_thumb: campaign.contents_displays[i].content_file,
          isContentMixed: false,
          promised_shows_subsidized: campaign.contents_displays[i].promised_shows_subsidized,
          promised_shows: campaign.contents_displays[i].promised_shows - campaign.contents_displays[i].promised_shows_subsidized,
          id: content.content_id ? content.content_id: content.id,
          length: content.length ? content.length  : null,
          content: {
            file: content.content_file ? content.content_file: content.file,
            file_thumb: content.content_file ? content.content_file: content.file,
            width: content.content_width ? content.content_width: content.width,
            height: content.content_height ? content.content_height: content.height,
            id: content.content_id ? content.content_id: content.id,
            name: content.content_name ? content.content_name: content.name,
            type: content.content_type ? content.content_type: content.type,
            ready: true,
            content_version_name: content.version_name ? content.version_name : content.content_version_name,
            length: content.content_length ? content.content_length: content.length,
          },
          rules: content.rules && content.rules.length > 0 ? JSON.parse(content.rules) : [],
        }
        //put subsidized the screen depending of paid_amount
        this.updateBundles = this.props.updateBundles(campaign.bundles)
        let subsidized_price = campaign.contents_displays[i].paid_amount_with_profit == "0.00" ? true : false
        this.props.addCustomContentFromCampaign(data.relationId,
                                                data.indice_relation,
                                                data.active,
                                                data.displayId,
                                                data.fill_screen,
                                                data.isContentMixed,
                                                data.promised_shows,
                                                data.length,
                                                data.content,
                                                data.rules,
                                                subsidized_price,
                                                data.promised_shows_subsidized,
                                                );
        if(!campaign.new_ssp) {
          this.props.updateCustomContentRequestActive(data.content.id, data.displayId, data.relationId, data.active);
        }
      })
    }

    this.props.activateEditCampaign(campaign)

  }

  displayPendingPaymentError = () => {
    notification.error({
      duration: 5,
      placement: "bottomRight",
      message: this.props.t('There was an error'),
      description:
      this.props.t('You must first delete the pending payment before retrying a new payment')
    });
  };

  displayMapModal = () => {
    this.setState({ displayMapModal: true });

    // const { currentCampaign } = this.props;
    // currentCampaign.contents_displays.forEach(cd => {
    //   this.props.getDisplayInfo(cd.display_id);
    // });
  };

  openDisplay(display) {
    this.setState({ currentDisplay: display });
  }

  closeDisplay(display) {
    this.setState({ currentDisplay: null });
  }

  render() {
    const { t, i18n, user , isSuperUser, editingCampaign, audienceCampaign } = this.props;
    const { audienceModaVisible } = this.state;

    const campaign = this.props.currentCampaign != null ? this.props.currentCampaign : {
      contents_displays: []
    };

    const isUserOwnerOfCampaign = user && user.company && (user.company.id === campaign.company_id);

    let notPaused = false;
    if (this.state.isAgencyOwner) {
      notPaused = campaign.contents_displays.some(contentDisplay => contentDisplay.active === 1);
    }
    moment.locale(i18n.language);

    const columns = [
      {
        title: t('Day'),
        key: "created_at",
        render: (_, record) => (
          <span>{moment(record.created_at).format("DD/MM/YYYY HH:mm")}</span>
        )
      },
      {
        title: t('Payment method'),
        key: "gateway",
        render: (_, record) => (
          <span>
            {
              {
                mercadopago_custom: t('Credit/debit card'),
                mercadopago_basic: t('MercadoPago'),
                publinet: t('Cash/Transfer')
              }[record.gateway]
            }
          </span>
        )
      },
      {
        title: t('Status'),
        key: "status",
        render: (_, record) => (
          <span>
            {
              {
                approved: t('Approved'),
                pending: t('Pending'),
                in_process: t('Processing'),
                rejected: t('Rejected'),
                cancelled: t('Cancelled'),
                refunded: t('Refunded'),
                cancelledin_mediation: t('In dispute'),
                charged_back: t('Voided by claim')
              }[record.status]
            }
          </span>
        )
      },
      {
        key: "detail",
        render: (_, record) => (
          <>
            <Link style={{marginRight: "10px"}} to={"/payment/" + record.id}>
              <Button
              shape="circle"
              icon="eye"
              />
            </Link>
            {record.status === "pending" && (
                <Button
                  style={{marginRight: "10px"}}
                  shape="circle"
                  icon="delete"
                  onClick={() => this.props.deletePayment(record.id)}
                />
            )}
            {(record.status !== "cancelled" &&
              (user && hasAccessRole(user.roles,
                [UserRoleEnum.agencyMember,
                UserRoleEnum.superModeratorMediaCompany,
                UserRoleEnum.superAdmin,
                UserRoleEnum.planner,
                UserRoleEnum.companyMember])))
              && (
              <CardOdcModal title={t("Purchase orders")} data={campaign.purchase_order_agency} t={t}/>
            )}
          </>
        )
      },
    ];

    const campaignPending = campaign.payment_status === "pending" || campaign.payment_status === "cancelled" || campaign.payment_status === "rejected" || campaign.payment_status === "cancelledin_mediation" || campaign.payment_status === "charged_back" || campaign.payment_status === "in_process";
    const paymentPending = this.props.payments.some(
      p => p.status === "pending"
    );
    if (user && hasAccessRole(user.roles, [UserRoleEnum.agencyMember,UserRoleEnum.companyMember])) {
      var isAgency = true
    } else {
      var isAgency = false
    }

    const sameDisplays = []
    campaign.contents_displays.forEach(relation => {
      if (!sameDisplays[relation.display_id]) {
        sameDisplays[relation.display_id] = []
      }
      if (relation.content_type === 'smartv2' && relation.content_child_contents) {
        for (var i = 0; i < relation.content_child_contents.length; i++) {
          relation = {...relation,
            content_name: relation.content_child_contents[i].name,
            file: relation.content_child_contents[i].file,
            file_thumb: relation.content_child_contents[i].file_thumb,
            content_file: relation.content_child_contents[i].file,
            content_width: relation.content_child_contents[i].width,
            content_height: relation.content_child_contents[i].height,
            content_type: relation.content_child_contents[i].type,
            length: relation.content_child_contents[i].length,
            rules: relation.content_child_contents[i].rules,
            max_impressions_per_day: relation.content_child_contents[i].max_impressions_per_day
          }
          sameDisplays[relation.display_id].push(relation);
        }
      } else {
        sameDisplays[relation.display_id].push(relation);
      }
    })

    var sliderSettings = {
      arrows: true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SliderNextArrow />,
      prevArrow: <SliderPrevArrow />
    };

    if (this.props.fetchingCampaign) {
      return (
        <CampaignHolder>
          <CampaignTopbar
            campaign={campaign}
            onClickMapButton={this.displayMapModal}
            totalShows={this.state.totalShows}
            loadInitialReport={this.state.loadInitialReport}
          />
          <Row
            className="campaignContainer"
            type="flex"
            justify="center"
            align="middle"
          >
            <Col xs={22} sm={22} md={22} lg={22} xl={22}>
              <h1 className="CampaignTitle">{campaign.name} (id: {campaign.id})</h1>
              <Card className="tableCard">
                <Row type="flex" justify="center" gutter={40}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: "20px" }}>
                    <Skeleton title active paragraph={{ rows: 4 }} />
                  </Col>
                </Row>
              </Card>
              <h3>{t('Your Ads')}</h3>
              <Fragment>
                <Row type="flex" justify="center" gutter={40}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: "20px" }}>
                    <Skeleton title active paragraph={{ rows: 4 }} />
                  </Col>
                </Row>
                <Row type="flex" justify="center" gutter={40}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: "20px" }}>
                    <Skeleton title active paragraph={{ rows: 4 }} />
                  </Col>
                </Row>
              </Fragment>
            </Col>
            <Col xs={0} sm={0} md={0} lg={0} xl={5} />
          </Row>
        </CampaignHolder>
      );
    }

    return (
      <CampaignHolder>
        <CampaignTopbar
          campaign={campaign}
          onClickMapButton={this.displayMapModal}
          totalShows={this.state.totalShows}
        />
        <Row
          className="campaignContainer"
          type="flex"
          justify="center"
          align="middle"
        >
          <Col xs={22} sm={22} md={22} lg={22} xl={22}>
            <div className="contentNameButton">
              <h1 className="campaignTitle" style={{ margin: 'auto' }}>
                {campaign.name} (id: {campaign.id})
              </h1>
              <div className="campaignTitle" style={{ textAlign: 'right' }}>
                <ReplicateCampaignContainer
                  campaignId={campaign.id}
                  name={campaign.name}
                  to_date={campaign.start_date}
                  end_date={campaign.end_date}>
                </ReplicateCampaignContainer>
              </div>
            </div>
            <Card className="tableCard">
              <div className="cardsInfoCampaign">
                <Card className="infoTableCard">
                  {t("Campaign created by")}
                  <br />
                  <b style={{ fontSize: "16px" }}>
                    {campaign.creator && campaign.creator.first_name}{" "}
                    {campaign.creator && campaign.creator.last_name}
                  </b>
                </Card>
                <Card className="infoTableCard">
                  {t("the day")}
                  <br />
                  <b style={{ fontSize: "16px" }}>
                    {moment(campaign.created_at).format("LLLL")}
                  </b>
                </Card>
                <Card className="infoTableCard">
                  {t("for a total amount of")}
                  <br />
                  <b style={{ fontSize: "16px" }}>
                    {campaign.currency ?
                      <NumberFormat
                        value={campaign.total_price_with_profit}
                        currency={campaign.currency}
                        tax={campaign.invoice_issuing.alpha_2_code != 'F'}
                      />:null
                    }
                  </b>
                </Card>
                {campaign.invoice_issuing && campaign.invoice_issuing.name ? (
                  <Card className="infoTableCard">
                    {t("Campaign country")}
                    <br />
                    <b style={{ fontSize: "16px" }}>
                      {campaign.invoice_issuing.name}
                    </b>
                  </Card>
                ) : null }
                { isAgency && campaign && campaign.id? (
                  <Fragment>
                      {campaign.client ? (
                        <Card className="infoTableCard">
                          {t("Client")}
                          <br />
                          <b style={{ fontSize: "16px" }}>
                            {campaign.client ? campaign.client.name: t("There is no client")}
                          </b>
                        </Card>) : null
                      }
                      {(campaign.brand && (Array.isArray(campaign.brand) && campaign.brand.length === 0) )|| !campaign.brand ? null : (
                        <Card className="infoTableCard">
                          {t("Mark")}
                          <br />
                          <b style={{ fontSize: "16px" }}>
                            {campaign.brand.name ? campaign.brand.name: t("There is no brand")}
                          </b>
                        </Card>)
                      }
                      { !(!campaign.smart_campaign && !isSuperUser ) ? // if traditional and user is not super, no show dsp
                        <Card className="infoTableCard">
                          DSP
                          { campaign.external_dsp && campaign.external_dsp.name != "Latinad" ?
                            <Tooltip
                              placement="right"
                              style={{marginBottom: "25px"}}
                              title={
                              <span>{t("info_dsp")}</span>
                              }>
                                <Icon
                                  type="info-circle"
                                  style={{ marginLeft: "10px"}}
                                />
                                <br />
                                <b style={{ fontSize: "16px" }}>
                                  {(campaign.external_dsp && campaign.external_dsp.name) || "Latinad"}
                                </b>
                            </Tooltip>
                          : <><br /> <b style={{ fontSize: "16px" }}> Latinad </b> </>}
                        </Card>: null
                      }
                  </Fragment>
                ) : null }
              </div>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <br />
                <Collapse
                  expandIconPosition='left'
                >
                  <Panel header={t("Your payments")} key="1" extra={statePaymentCampaign(campaign.payment_status, t, isAgency)}>
                    {/* when is external dps and not super user, not can create new payment */}
                    {(  campaign.external_dsp_id == null ||
                        !campaign.smart_campaign ) || isSuperUser?
                        <><Table
                          rowKey="id"
                          loading={this.props.deletingPayment}
                          bordered
                          columns={columns}
                          dataSource={this.props.payments}
                          pagination={false}
                          scroll= { window.matchMedia("(max-width: 768px)").matches ?  { x: "max-content"} : {}}
                        />
                        { campaignPending && (
                          <Button
                            onClick={() =>
                              paymentPending
                                ? this.displayPendingPaymentError()
                                : this.props.history.push(
                                    `/campaign/${campaign.id}/payment`
                                  )
                            }
                            className="checkoutButton"
                            type="primary"
                            style={{ marginTop: 10 }}
                          >
                            {t("Create new payment")}
                          </Button>
                        )}
                    </>
                    :
                      <div style={{display: "flex", justifyContent: "center"}}>
                        <div style={{width: "65%"}}>
                          <p style={{textAlign: "center"}}>{t('info_payment_first')} (<strong>{campaign.external_dsp.name}</strong>) {t('info_payment_second')}</p><br/>
                          <Alert description={t('info_payment_third')} type="info" showIcon />
                        </div>
                      </div>
                    }
                  </Panel>
                </Collapse>
              </Col>
            </Card>
            <h2 className="SpotsTitle">{t("Your ads for each screen")}</h2>
            <div style={{ textAlign: "right" }}>
              {campaign.payment_status === "pending" &&
              user && hasAccessRole(user.roles, [
                UserRoleEnum.agencyMember,
                UserRoleEnum.companyMember,
              ]) ? (
                <Tooltip placement="bottom" title={t("Generate proposal")}>
                  <Button
                    className="reportButton"
                    loading={this.state.reportLoading}
                    onClick={() => this.getProposal(campaign.id)}
                  >
                    {t("Proposal")}
                  </Button>
                </Tooltip>
              ) : null}
              { this.props.user && this.props.user.company ? (
                  <Tooltip
                    placement="bottom"
                    title={t("View campaign in Campaign Manager")}
                  >
                    <Button
                      className="reportButton"
                      onClick={() => window.open(getCMSLink(this.props.token, campaign.id), "_blank", "noopener,noreferrer")}
                    >
                      {t("CMS")}
                    </Button>
                  </Tooltip>
                ) :  null
              }
              {campaign.status === "ready" ? (
                <Button
                  className="reportButton"
                  loading={this.state.reportLoading}
                  onClick={() => this.getReport(campaign)}
                >
                  {t("See detailed report")}
                </Button>
              ) : null}
              {(campaign.payment_status === "pending" ||
                campaign.payment_status === "cancelled" ||
                campaign.payment_status === "pre_approved" ||
                (user && hasAccessRole(user.roles, [
                  UserRoleEnum.superAdmin,
                  UserRoleEnum.superModeratorAgency,
                  UserRoleEnum.companyMember,
                ]) && campaign.payment_status === "approved")) ? (
                <Tooltip
                  placement="bottom"
                  title={!isUserOwnerOfCampaign ?
                    t("The logged-in user is not the owner of this campaign") :
                    t("Edit campaign")}>
                  <Button
                    className={ isUserOwnerOfCampaign ? "editButton": ""}
                    onClick={() => this.editCampaignModal()}
                    disabled={!isUserOwnerOfCampaign}
                  >
                    {t("Edit campaign")}
                  </Button>
                </Tooltip>
              ) : null}
              {( campaign.payment_status === "approved" && campaign.status === "ready" && this.state.isAgencyOwner ) ? (
                <Button
                  className="editButton"
                  onClick={() => this.pausedCampaignModal()}
                  loading={ editingCampaign }
                >
                  {!notPaused ? t("Activate campaign"): t("Pause campaign")}
                </Button>
              ) : null}

              <Modal
                title={ !notPaused? t("Activate campaign"): t("Pause campaign")}
                visible={this.state.pausedCampaignModal}
                onCancel={() => this.setState({ pausedCampaignModal: false })}
                width={500}
                footer={[
                  <Button key="back"
                      onClick={() => {this.pauseCampaign(campaign, notPaused)}}
                      loading={ editingCampaign }>
                      { !notPaused? t("Activate campaign"): t("Pause campaign")}
                  </Button>,
                ]}
              >
                <h1 style={{ fontSize: "15px", padding: "15px", color: Colors.primary }}>
                  { !notPaused ?
                    t("The campaign will be activated, which means it will start running and begin consuming budget again until it is paused."):
                    t("The campaign will be paused, which means it will stop running and will not consume budget until it is reactivated.")}
                </h1>
              </Modal>

              <Button
                className="reportButton"
                // loading={this.state.reportLoading}
                onClick={this.handleAudienceModal}
              >
                {t("Audience")}
              </Button>
            </div>
            {sameDisplays.map((displays, index) => {
              return (
                <Fragment key={index}>
                  <Slider
                    ref={(slider) => (this.slider = slider)}
                    {...sliderSettings}
                  >
                    {displays.map((display, index) => (
                      <AdScreenCardContainer
                        key={display.id}
                        display={display}
                        campaign={campaign}
                        index={index}
                        spotsLength={displays.length}
                      />
                    ))}
                  </Slider>
                </Fragment>
              );
            })}
          </Col>
          <Col xs={0} sm={0} md={0} lg={0} xl={5} />
        </Row>

        <Modal
          title={t("Audience summary")}
          visible={audienceModaVisible}
          onCancel={this.handleAudienceModal}
          footer={[
            <Button key="back" onClick={this.handleAudienceModal}>
              OK
            </Button>,
          ]}
          width={1000}
          style={{ top: "10px" }}
        >
          <AudienceSummaryComponent
            t={t}
            start_date={campaign.start_date}
            end_date={campaign.end_date}
            displays={campaign.contents_displays}
            user={user}
            campaign={campaign}
            audienceCampaign={audienceCampaign}
            proposalAudience={this.props.proposalAudience}
            getProposalAudience={this.props.getProposalAudience}
          />
        </Modal>

        <Modal
          title={t("Consider the following")}
          onCancel={() => this.setState({ editCampaignModal: false })}
          visible={this.state.editCampaignModal}
          onOk={() => this.editCampaign(campaign)}
          footer={[
            <Button
              key="back"
              onClick={() => this.setState({ editCampaignModal: false })}
            >
              {t("Cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.state.loadingEditCampaign}
              onClick={() => this.editCampaign(campaign)}
            >
              {t("Go to edit")}
            </Button>,
          ]}
        >
          {campaign.payment_status === 'pending' ?
            <p style={{ fontSize: "15px", padding: "30px", color: Colors.primary }}>
              {t("When entering the edit mode of a campaign ")}
              <b>{t("some prices may be changed ")}</b>
              {t(
                "(only in cases where screen owners have updated those prices), since this campaign does not have payments generated nor has it been launched, therefore that prices have not been frozen."
              )}
            </p> :
            <p style={{ fontSize: "15px", padding: "30px", color: Colors.primary }}>
              {t("Entering campaign edit mode. This campaign has approved payment, so only elements that do not alter its price can be edited.")}
            </p>
          }
        </Modal>

        <Modal
          footer={null}
          onCancel={() => this.setState({ displayMapModal: false })}
          visible={this.state.displayMapModal}
        >
          <MapHolder>
            <DisplayMap
              displays={campaign.contents_displays}
              currentDisplay={this.state.currentDisplay}
              openDisplay={this.openDisplay.bind(this)}
              closeDisplay={this.closeDisplay.bind(this)}
              loadingElement={<div style={{ width: "100%", height: `100%` }} />}
              containerElement={
                <div
                  className="mapHeight"
                  style={{
                    width: "100%",
                    height: `500px`,
                    borderRadius: "10px",
                  }}
                />
              }
              mapElement={
                <div
                  style={{
                    width: "100%",
                    height: `100%`,
                    borderRadius: "10px",
                  }}
                />
              }
            />
          </MapHolder>
        </Modal>
      </CampaignHolder>
    );
  }
}

const Extended = withTranslation()(CampaignComponent);
Extended.static = CampaignComponent.static;

export default withRouter(Extended);
